import { ReactComponent as GoodSignal } from "assets/network/signal-good.svg";
import { ReactComponent as LostSocket } from "assets/network/socket-no-connect.svg";
import { useEffect } from "react";
import { useState } from "react";
import { useSocket } from "socketConfig/socketContext";

const InternetSpeed = () => {
  const { socket } = useSocket();
  const [socketStatus, setSocketStatus] = useState<
    "connecting" | "connected" | "disconnected"
  >("connecting");

  useEffect(() => {
    const handleConnect = () => {
      setSocketStatus("connected");
    };

    const handleDisconnect = () => {
      setSocketStatus("disconnected");
    };

    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);

    return () => {
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
    };
  }, []);

  return (
    <>
      {socketStatus === "connecting" && (
        <GoodSignal width="1.5em" height="1.5em" />
      )}
      {socketStatus === "connected" && (
        <GoodSignal width="1.5em" height="1.5em" />
      )}
      {socketStatus === "disconnected" && (
        <LostSocket width="1.5em" height="1.5em" />
      )}
    </>
  );
};

export default InternetSpeed;
