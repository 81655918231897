import { isEmpty } from "lodash";
import { triggerAlert } from "../appSetting/actions";
import { WORKSPACE_CONFIG } from "./types";

export const initWorkspace = (workspaceName: string = "", workspaceConfig: any = {}) => async (dispatch: any) => {
  let configObj = {
    workspaceName,
    workspaceConfig
  }
  if (isEmpty(configObj.workspaceName)) {
    configObj = {
      ...configObj,
      workspaceName: localStorage.getItem("workspace") || ""
    }
  }
  if (isEmpty(configObj.workspaceConfig)) {
    configObj = {
      ...configObj,
      workspaceConfig:JSON.parse(localStorage.getItem("config") || "{}")
    }
  }
  try {
    dispatch({
      type: WORKSPACE_CONFIG,
      payload: configObj,
    });
  } catch (e: any) {
    dispatch(triggerAlert(e?.response?.data?.failMsg.toString(), "error"));
  }
};

