import React, { createContext, useContext, useState, useEffect, ReactNode,useMemo } from 'react';
import io from 'socket.io-client';
import { useDispatch, useSelector } from "react-redux";
import { updateSocket } from 'redux/auth/actions';
import { workspaceSelector } from 'redux/workspace/selector';
interface SocketContextType {
  socket: any;
}

export const SocketContext = createContext<SocketContextType>({ socket: null });

export const useSocket = () => useContext(SocketContext);

interface SocketProviderProps {
  children: ReactNode;
  // defaultSocketUrl: string;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<any>(null);
  const {workspaceConfig }: any = useSelector(workspaceSelector);
  const dispatch=useDispatch()
  
  const defaultSocketUrl=useMemo(()=>{
    console.log("#########",workspaceConfig,workspaceConfig?.REACT_APP_SOCKET_API)
    return workspaceConfig?.REACT_APP_SOCKET_API||""
  },[ workspaceConfig?.REACT_APP_SOCKET_API])
  console.log("5555555",workspaceConfig,workspaceConfig?.REACT_APP_SOCKET_API)

  useEffect(() => {
    const newSocket = io(defaultSocketUrl, {
      transports: ['websocket'],
      reconnectionDelay: 5000,
      reconnection: true,
      autoConnect: false,
    });
    setSocket(newSocket);
    dispatch(updateSocket(newSocket))

    // Clean up socket connection when component unmounts
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [defaultSocketUrl,dispatch]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};
