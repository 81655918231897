import { createSelector } from "reselect";

export interface WorkspaceInfo {
  workspaceName: string;
  workspaceConfig: any;
}

interface RootState {
  workspace: WorkspaceInfo;
}

// This used to memorize the selector value
export const workspaceSelector = createSelector(
  (state: RootState) => state.workspace,
  (workspace: WorkspaceInfo) => {
    return {
      workspaceName: workspace.workspaceName,
      workspaceConfig: workspace.workspaceConfig
    };
  }
);
