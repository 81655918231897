import { AxiosResponse } from "axios";
import authService, { signUp, verifySignUp } from "../../services/auth.service";
import {
  authenticationEvent,
  changeProfileStatus,
  loginStatus,
  logoutEvent,
  logoutNotification,
  profileStatus,
  reloginEvent,
  updateAgentCommunityStatues,
  updateAgentCommunityVisibility,
} from "../../socketConfig/socketEvent";
import localize from "../../utils/localize";
import { loading, stopLoading, triggerAlert } from "../appSetting/actions";
import {
  ADD_TOKEN,
  DISABLE_CURRENT_TAB_SESSION,
  FORGET_PASSWORD,
  GET_PROFILE,
  LOADING_LOGIN,
  LOGIN_STATUS,
  OTHER_LOGOUT,
  RESET_PASSWORD,
  RESET_REGIST,
  SOCKET,
  UPDATE_PROFILE_PREFERENCES,
  UPDATE_PROFILE_STATUE,
  UPDATE_PROFILE_STATUE_COMMUNITY,
  USER_LOGOUT,
  USER_SIGNUP,
  USER_VERIFY,
} from "./types";
// import { toString } from "lodash";
import { controller } from "config/httpComman";
import { has } from "lodash";
import { errorHandling } from "utils/errorHandling";
import { Preferences } from "./selector";
// import { controller } from "App";

const initialInboxNotificationPreference = {
  active: {
    sound: true,
    desktop: true,
  },
  inactive: {
    sound: false,
    desktop: false,
  },
  escalated: {
    sound: true,
    desktop: true,
  },
  escalatedUnassign: {
    sound: false,
    desktop: false,
  },
  transferAgent: {
    sound: false,
    desktop: false,
  },
  transferSuperAgent: {
    sound: false,
    desktop: false,
  },
};
const initialCommunityNotificationPreference = {
  channel: true,
  group: true,
  direct: true,
};

export const loginSocketConfirm = (jwt: string, socket: any) => async (dispatch: any) => {
  try {
    authenticationEvent(jwt);

    // check loginEvent
    loginStatus((data: { status: "SUCCESS" }) => {
      if (data.status === "SUCCESS") {
        localStorage.setItem("socketId", socket.id);
        dispatch(addSocketId(data, socket));
        return;
      } else {
        socket.disconnect(true);
        dispatch(triggerAlert(localize("login_failed"), "error"));
      }
    });
  } catch (error) {
    console.log({ loadSessionsError: error });
  }
};

export const addSocketId =
  (data: { status: "SUCCESS" }, socket: any) => async (dispatch: any) => {
    try {
      if (data.status === "SUCCESS") {
        dispatch({
          type: LOGIN_STATUS,
          payload: {
            loginStatus: data,
            socketId: socket.id ?? localStorage.getItem("socketId"),
          },
        });
      }
    } catch (e) {
      dispatch(triggerAlert(localize("logout_failed"), "error"));
    }
  };

export const gettingProfile = () => async (dispatch: any) => {
  try {
    const response: AxiosResponse = await authService.getProfile();
    if (response.status === 200) {
      const { preferences: profilePreferences } = response.data;

      dispatch({
        type: GET_PROFILE,
        payload: { profile: response.data },
      });

      //incase the profile doesn't have notifications preferences, set the default preferences
      if (
        !profilePreferences ||
        !has(profilePreferences, "notifications") ||
        !has(profilePreferences, "notifications.inbox") ||
        !has(profilePreferences, "notifications.community")
      ) {
        dispatch(
          updateProfilePreference({
            ...(profilePreferences || {}),
            notifications: {
              inbox: initialInboxNotificationPreference,
              community: initialCommunityNotificationPreference,
              ...(profilePreferences?.notifications || {}),
            },
          })
        );
      }
    }
  } catch (er: any) {
    errorHandling(er, dispatch, "login_failed");
  }
};

export const login = (user: any) => async (dispatch: any) => {
  try {
    dispatch(updateLoadingLogin(true));
    const response: AxiosResponse = await authService.postLogin(user);
    if (response.status === 200 && response.data.hasOwnProperty("token")) {
      localStorage.setItem("token", response.data.token);
      //   localStorage.setItem("location", '/dashboard');
      dispatch(addToken());
      dispatch(updateLoadingLogin(false));
      return response;
    }
    dispatch(triggerAlert(response?.data?.toString() || "", "error"));
    dispatch(updateLoadingLogin(false));
  } catch (e: any) {
    // console.log({e})
    dispatch(triggerAlert(e?.response?.data?.failMsg.toString(), "error"));
    dispatch(updateLoadingLogin(false));
  }
};

export const addToken = () => async (dispatch: any) => {
  try {
    dispatch({
      type: ADD_TOKEN,
      payload: { token: localStorage.getItem("token") },
    });
  } catch (e) {
    dispatch(triggerAlert(localize("logout_failed"), "error"));
  }
};

export const logoutTabInstances = () => async (dispatch: any) => {
  try {
    logoutNotification(() => {
      dispatch({
        type: OTHER_LOGOUT,
      });
    });
  } catch (error) {
    console.log({ loadSessionsError: error });
  }
};
export const logout = (token: string, socket: any) => async (dispatch: any) => {
  try {
    // console.log('logout')
    localStorage.removeItem("token");
    localStorage.removeItem("socketId");
    logoutEvent();
    socket.disconnect(true);
    dispatch({
      type: USER_LOGOUT,
    });
    controller.abort();
    window.location.reload();
    // localStorage.setItem('location','/');
  } catch (e) {
    dispatch(triggerAlert(localize("logout_failed"), "error"));
  }
};

export const reloginAction = (socket: any) => async (dispatch: any) => {
  try {
    reloginEvent((data: { status: "SUCCESS" }) => {
      if (data.status === "SUCCESS") {
        dispatch({
          type: DISABLE_CURRENT_TAB_SESSION,
        });
        localStorage.removeItem("socketId");
        socket.disconnect(true);
        controller.abort();
      }
    });
  } catch (e) {
    dispatch(triggerAlert(localize("logout_failed"), "error"));
  }
};

export const updatingProfileStatus =
  (status: boolean, customStatus: number) => async (dispatch: any) => {
    try {
      await changeProfileStatus(status, customStatus);
    } catch (er: any) {
      errorHandling(er, dispatch, "agent_status_failed");
    } finally {
      dispatch(stopLoading());
    }
  };

export const updatingProfileStatusCommunity =
  (status: string) => async (dispatch: any) => {
    try {
      await updateAgentCommunityStatues(status);
      dispatch({
        type: UPDATE_PROFILE_STATUE_COMMUNITY,
        payload: status,
      });
    } catch (er: any) {
      errorHandling(er, dispatch, "agent_status_failed");
    }
  };

export const updateProfileVisibilityCommunity =
  (status: boolean) => async (dispatch: any) => {
    try {
      updateAgentCommunityVisibility(status);
    } catch (er: any) {
      errorHandling(er, dispatch, "agent_status_failed");
    } finally {
      dispatch(stopLoading());
    }
  };

export const updateProfileStatus = () => async (dispatch: any) => {
  try {
    profileStatus(
      (data: {
        message: string;
        status: string;
        visibility: boolean;
        customStatus: number;
      }) => {
        if (data.status === "SUCCESS") {
          dispatch({
            type: UPDATE_PROFILE_STATUE,
            payload: {
              visibility: !data.visibility,
              customStatus: data.customStatus,
            },
          });
        }
      }
    );
  } catch (er: any) {
    errorHandling(er, dispatch, "agent_status_failed");
  } finally {
    dispatch(stopLoading());
  }
};

export const updateProfilePreference =
  (data: Preferences) => async (dispatch: any) => {
    try {
      dispatch(loading());
      dispatch({
        type: UPDATE_PROFILE_PREFERENCES,
        payload: data,
      });

      await authService.updatePreferences({ preferences: data });

      //dispatch(gettingProfile());
      dispatch(triggerAlert(localize("update_profile_success"), "success"));
    } catch (er: any) {
      errorHandling(er, dispatch, "update_profile_failed");
    } finally {
      dispatch(stopLoading());
    }
  };

export const register = (data: signUp) => async (dispatch: any) => {
  try {
    dispatch(loading());

    await authService.signUp(data);
    dispatch({
      type: USER_SIGNUP,
      payload: data,
    });
    dispatch(triggerAlert(localize("signup_success"), "success"));
  } catch (er: any) {
    errorHandling(er, dispatch, "signup_failed");
  } finally {
    dispatch(stopLoading());
  }
};

export const resetRegister = () => (dispatch: any) => {
  dispatch({
    type: RESET_REGIST,
  });
};

export const verifyRegister = (data: verifySignUp) => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response: AxiosResponse = await authService.verifySignup(data);
    dispatch({
      type: USER_VERIFY,
      payload: response,
    });
    dispatch(triggerAlert(localize("verify_success"), "success"));
  } catch (er: any) {
    errorHandling(er, dispatch, "verify_failed");
  } finally {
    dispatch(stopLoading());
  }
};

export const forgetPassword = (email: string) => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response: AxiosResponse = await authService.forgetPassword(email);
    dispatch({
      type: FORGET_PASSWORD,
      payload: response,
    });
    dispatch(triggerAlert(localize("forget_password_success"), "success"));
  } catch (er: any) {
    errorHandling(er, dispatch, "forget_password_failed");
  } finally {
    dispatch(stopLoading());
  }
};

export const resetPassword =
  (data: { password: string; resetKey: string }) => async (dispatch: any) => {
    try {
      const workspaceConfig: any = JSON.parse(localStorage.getItem("config") || "{}") || {}
      dispatch(loading());
      const response: AxiosResponse = await authService.resetPassword(data);
      dispatch({
        type: RESET_PASSWORD,
        payload: response,
      });
      dispatch(triggerAlert(localize("reset_password_success"), "success"));
      window.location.href = `${workspaceConfig?.REACT_APP_HOST}`;
    } catch (er: any) {
      errorHandling(er, dispatch, "reset_password_failed");
    } finally {
      dispatch(stopLoading());
    }
  };

export const updateLoadingLogin =
  (isLoading: boolean) => async (dispatch: any) => {
    try {
      dispatch({
        type: LOADING_LOGIN,
        payload: isLoading,
      });
    } catch (er: any) {
      errorHandling(er, dispatch, "update_loading_login");
    } finally {
    }
  };
export const updateSocket =
  (data: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: SOCKET,
        payload: data,
      });
    } catch (er: any) {
      errorHandling(er, dispatch, "update_loading_login");
    } finally {
    }
  };
