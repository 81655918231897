import {
  ADD_WORKSPACE,
  WORKSPACE_CONFIG
} from "./types";

interface IntialState {
  workspaceName: string;
  workspaceConfig: any;
}

const initialState: IntialState = {
  workspaceName: "",
  workspaceConfig: {}
};

export default function workspaceReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case ADD_WORKSPACE:
      return {
        ...state,
        workspaceName: payload.configObj,
      };
    case WORKSPACE_CONFIG:{
      console.log("uuuuuuuuu",payload.workspaceConfig,typeof payload.workspaceConfig)
      return {
        ...state,
        workspaceName:payload.workspaceName,
        workspaceConfig: payload.workspaceConfig
      };
    }
    default:
      return state;
  }
}
