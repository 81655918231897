export enum AppConfig {
  SEARCH_DEBOUNCE_DELAY_MS = 500,
  SPEED_TEST_DELAY_MS = 500,
  UPDATE_TIME_DELAY = 500,
  GET_MESSAGE_PATCH_SIZE = 30,
  GET_SESSIONS_PATCH_SIZE = 20,
  BORDER_RADIUS_MAX = "0.5em",
  BORDER_RADIUS_MIN = "0.3em",
  NO_BORDER_RADIUS = "0.3em",
  MAX_ATTACHMENT_PER_REQUEST = 10,
  BORDER_SWITCH = "25px",
  HEGIHT_ACTIVE_TAB="0.325em",
  BORDER_ACTIVE_TAB="0.325em",
  INPUT_HEIGHT="2em"
}