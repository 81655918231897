import axios from "axios";

export const controller = new AbortController();
export const signal = { signal: controller.signal };
const http = axios.create({
  baseURL:"",
  // withCredentials:true,
  headers: {
    "Content-type": "application/json",
  },
});

http.interceptors.request.use((req: any) => {
  if (localStorage.getItem("token")) {
    req.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  }
  if (localStorage.getItem("config")) {
    const workspaceConfig:any=JSON.parse(localStorage.getItem("config")||"{}")||{}
    req.baseURL = `${workspaceConfig?.REACT_APP_API_HOST}`;
  }
  return req;
});

http.interceptors.response.use(
  (res: any) => {
    return res;
  },
  (err: any) => {
    if (err.response.status === 401 || err.response.status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("socketId");
      controller.abort();
      window.location.reload();
    }
    return Promise.reject(err);
  }
);

export default http;
